.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(to right, #8fa8ee, #3676c0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
